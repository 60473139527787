.remove-vart-btn{
   bottom: 1px;
   right: 230px; 
}
.address-text{
   font-size: 18px ;
   color: black;
}
.address-heading{
   font-size: 20px;
}
/* .address-card{
   width: 540px; 
} */
.text-main{
   font-size: 13px !important;
}
.added-card-date-input{
   border: 1px solid #dee2e6;
   border-radius: 0.375rem;
   padding-inline: 0.75rem;
   width: 100%;
   height: 38px;
}
.react-datepicker-wrapper{
   width: 100%;
}