.bg-growing{
    background-color:#000C24 !important;
  }
  .grwo-font{
    font-size: 20px !important;
    width: 58%;
  }
  .bg-growing-content{
    background-color: #ECF2FF;
  }
  .bg-grow-change{
    background-color: #F5F8FF;
  }
  .growing-box{
      width: 300px !important;
      height: 180px !important ;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  
  
  }
  @media screen and (max-width:800px){
    .grwo-font{
        width:90% !important;
       
    }
    }
  
  
  .growing-tile-heading{
    text-align: center;
    color: #D2584D;
      font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 136.19%;
  
  }
  .growing-tile-heading + span{
    display: block;
      text-align: center;
      width: 100%;
  }
  
  .swiper {
      width: 100%;
      height: 100%;
      max-width: 1500px !important;
      margin: 0 auto;
    }
    
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      
    }
    
    .swiper-slide img {
      display: block;
      width: 50%;
      max-width: 200px;
    
      height: 100%;
      object-fit: cover;
    }
  
    .slider{
      background:white;
    }
    .padding4rem{
      padding: 2rem 0!important;
    }
  
    .growing-container{
      padding: 2rem;
      width: 100%;
      display: grid;
      grid-gap:20px;
      justify-content: space-evenly;
      align-items: center;
      background:#000C24;
      grid-template-columns: repeat(4,1fr);
      border-bottom: 1px solid gray;
    }
    .growing-container>div{
      /* flex:0 1 100px */
    }
    .growing-container>div>*{
      color:white;
      text-align: center
    }
    
  
    @media screen and (max-width:800px) {
      .growing-container{
     
        grid-template-columns: repeat(2,1fr);
        
      }
      .swiperImg{
        width: 100% !important;
      }
    }