
/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */


/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Nunito+Sans:opsz@6..12&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
 
}
body {
  margin: 0;
  padding: 0 ;
  font-family: 'Nunito Sans', sans-serif !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;

}
ul li {
  list-style-type: none;
}
a{
  text-decoration: none;
}
.sectionYp{
  padding: 70px 0px;
}
.nav-text{
   font-size: 16px;
   color: black !important;
   font-weight:500 !important;
}
.red-b{
  background-color:  #b8242a !important;
}
.red-t{
  color: #b8242a !important;
}
.text-white{
  color: white !important;
}
.bg-white{
  background-color: white !important;
}
.p-10{
  padding-top: 20px;
  padding-bottom: 20px;
  
}
h1 , h2, h3, h4, h5, h6{
  font-family: 'Fira Sans', sans-serif !important;

}
p{
  font-size: 17px !important;
  font-family: 'Fira Sans', sans-serif !important;
}
.text-black{
  color:#62718D !important; 
}
.text-black1{
  color: #474747 !important;
}
.text-black2{
  color: #747474 !important;
}
.heading{
  font-size: 40px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  
   padding: 4px 0px;
   
}
.normal-text{
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight:400;
  font-size:17px;
  line-height:26px;
  text-align:justify;
}
.medium-text{
font-size: 16px;
font-weight: 500;
text-align: left;
}
.small-text{
  font-size: 21px;
  font-weight: 500;
  font-family: 'Fira Sans';
}
.overlay1{
  position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #000;
    z-index: 9999;
    opacity: 0.4;
}


.active{
  color:  #b8242a !important;

}
.red-btn{
  background-color: #b8242a;
  padding: 14px 24px; 
  border:none;
  font-family: 'Nunito Sans', sans-serif !important;
  color: white !important;
  border-radius: 8px;
  width: 197px;
  text-align: center;
  
  font-size: 15px !important;
  font-weight: 400;
}
.red-btn-disable{
  background-color: #d3b8b9;
  padding: 14px 24px; 
  border:none;
  font-family: 'Nunito Sans', sans-serif !important;
  color: white !important;
  border-radius: 8px;
  width: 197px;
  text-align: center; 
  font-size: 15px !important;
  font-weight: 400;
}
.small-red-btn{
  background-color: #b8242a;
  padding: 8px 12px; 
  border:none;
  font-family: 'Nunito Sans', sans-serif !important;
  color: white !important;
  border-radius: 8px;
  width: 140px;
  text-align: center;
  
  font-size: 15px !important;
  font-weight: 400;
}
.small-red-btn-disable{
  background-color: #d3b8b9;
  padding: 8px 12px; 
  border:none;
  font-family: 'Nunito Sans', sans-serif !important;
  color: white !important;
  border-radius: 8px;
  width: 140px;
  text-align: center; 
  font-size: 15px !important;
  font-weight: 400;
}
.white-btn{
  background-color: white;
  padding: 10px 34px; 
  border:none;
  color: #b8242a !important;
  width: 197px;
  border-radius: 4px;
  font-size: 15px !important;
  font-weight: 400;
}
.btn-primary{
  background-color: white !important;
}
.bg-lightblue{
  background-color: #EDF5F9 !important;
}
.fullHeight{
  min-height: 96vh !important;
}
.nav-margin{
  margin-top:62px !important;
}
.text-heading{
  font-size: 21px;
  font-weight: 600 !important;
}

.navbar{
  height: 60px;
}
.nav-shadow{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
}
.nav-conainer{
  z-index: 9999 !important;
}
/* footer */
.footer-item li a{
  color: white;
  font-size: 16px;
  margin-top: 10px !important;
  display: block;
}

.footer-item li{
  color: white;
}
.input-box{
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
 font-size: 13px !important;
}
.card-shadow{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;
}
.btn-logout{
   top: -100px !important;
   position: absolute !important;
   right: 0;
   width: 120px;
   
}
.footer-img-logo{
 
  width: 140px !important;

}


/* loader */
.loader-budget{
  position: fixed;
  top: 0;left: 0;
  height:100%;
  width:100%;
  z-index: 111;
  background-color: #fdfdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.loader-budget>img{
  position: absolute;
 width:100px;
 -webkit-animation: spin 2s linear infinite;
 animation: spin 2s linear infinite;
  
}
/* remove cart */
.service-info ul li{
   list-style-type: initial !important;
}

/* remove cart end */
/* @-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */

.badge {
  position: absolute;
  top: -6px;
  left: 18px;
  background-color: #b8242a;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
}
.servicecategory-sticky{
  position: sticky;
  top: 0;
}

.dropdown-menu[data-bs-popper]{
  top: 100%;
    left: -34px;
    margin-top: var(--bs-dropdown-spacer);
}
.brand-text{
  font-size: 18px !important;
  color: #b8242a !important;
}

@media (max-width:780px){
  .fullHeight{
    height: auto !important; 
    margin-bottom: 20px;
    
  }
  
  .servicecategory-sticky{
    position: sticky;
    top:'70px !important'
  }
  .red-btn-block{
    width: 100% !important;
    display: block !important;
   
  }
  
  .nav-margin{
    margin-top: 70px !important;
  }

  .white-btn, .red-btn{
       padding: 7px 18px !important;
       width: 100px !important;
       font-size: 10px !important;
  }
  .red-btn-disable{
    padding: 7px 18px !important;
    width: 100px !important;
    font-size: 10px !important;
}
    h4{
      font-size: 12px !important;
    }
    p{
      font-size: 12px !important;
    }
    .categories-text{
      font-size: 11px !important;
    }

  h3{
    font-size: 14px;
  }
  .text-heading{
    font-size: 13px !important;
    font-weight:700 !important;
  }
  .text-black{
    font-size: 14px;
  }
.normal-text{
  font-size: 14px !important;
   line-height: 24px;
}
  /* footer */
  .brand-Img-footer{
    width: 100px !important;
    height: auto;
  }
  .footer-item li a{
    font-size: 13px;
    margin-top: 8px !important;
    display: block;
  }
  .footer-item li{
    font-size: 14px !important;
  }
.heading{
  font-size: 16px;
  line-height: 24px;
}
  
}
/* navbar */
.cart-icon {
  position: relative;
  font-size: 24px;
  cursor: pointer;
}
.brand-text{
  font-size: 32px !important;
  color: #b8242a !important;
}


/* Styles for the badge */


@media (max-width:480px){
  .red-btn-block{
    width: 100% !important;
    display: block !important;
     padding: 10px !important;
     font-size: 14px !important;
  }
  .offcanvas, .offcanvas-lg, .offcanvas-md, .offcanvas-sm, .offcanvas-xl, .offcanvas-xxl{
    --bs-offcanvas-width: 202px !important;
  }
  .badge {
  
    left: 16px;
  
  }
  .small-red-btn{
 
    width: 100%;
    text-align: center;
    
    font-size: 14px !important;
    font-weight: 400;
  }
  .small-red-btn-disable{
   
    width: 100%;
    text-align: center; 
    font-size: 14px !important;
    font-weight: 400;
  }
  .navbar div .navbar-toggler{
    border: none !important;
  }
}
